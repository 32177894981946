import React from 'react';

import '../NextChapter.css';
import luca from '../assets/luca.jpg';
import celebratingStanley from '../assets/celebrating-stanley.mp3';
function NextChapter() {
  return (
    <div className="NextChapter">
      <img src={luca} alt="ultrasound" className='luca' />
      <audio
        autoPlay
        controls
        loop
        muted={false}
        id="home-audio"
      >
        <source src={celebratingStanley} type="audio/mpeg"></source>
      </audio>
    </div>
  );
}

export default NextChapter;
