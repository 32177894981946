import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import exploringStanley from '../assets/exploring-stanley.mp3';
import '../Home.css';

function Home() {
  const navigate = useNavigate();
  const audioRef = useRef(null);
  const [hasPlayedAudio, setHasPlayedAudio] = useState(false);

  const [showInstructions, setShowInstructions] = useState(true);
  const [leftButtonClicks, setLeftButtonClicks] = useState(0);
  const [rightButtonClicks, setRightButtonClicks] = useState(0);
  const [response, setResponse] = useState('');
  const [showNextChapter, setShowNextChapter] = useState(false);

  const leftButtonMessages = [
    `Hmmm... no, that's not the right button at all.`,
    'Ah, the rebel, pressing the *wrong* button. Classic.',
    `Sure, keep pressing it. I'm sure this will end well.`,
    'You know, the right button is there for a reason. Just saying.',
    `Oh, you're *really* committed to this, aren't you? Bravo.`,
    `Okay, you know what? Maybe you have *too many* choices...`,
  ];

  const rightButtonMessagesEarly = [
    `Eager, aren't we? But no, not yet.`,
    `You haven't even *earned* the right to press this button.`,
    `Wait, wait! That was a little sooner than we thought. We admit that we weren't quite finished setting up...`,
    `Oh.... well... this is embarrassing... maybe check the other button one more time. Maybe it switched around on me.`,
  ];
  const playAudio = () => {
    if (!hasPlayedAudio && audioRef.current) {
      try {
        audioRef.current.play();
        setHasPlayedAudio(true);
      } catch (e) {
        console.warn('Tried to play audio but', e);
      }
    }
  };

  const handleLeftButtonClick = () => {
    playAudio();
    setLeftButtonClicks(leftButtonClicks + 1);
    const message =
      leftButtonMessages[leftButtonClicks % leftButtonMessages.length];
    setShowInstructions(false);
    setResponse(message);
  };

  const handleRightButtonClick = () => {
    playAudio();
    if (leftButtonClicks < rightButtonMessagesEarly.length) {
      const message =
        rightButtonMessagesEarly[
          rightButtonClicks % rightButtonMessagesEarly.length
        ];
      setRightButtonClicks(rightButtonClicks + 1);
      setResponse(message);
    } else {
      setResponse(
        `🎉 Congrats! You've finally followed the instructions correctly. (Took you long enough...)`
      );
      setShowNextChapter(true);
    }
    setShowInstructions(false);
  };

  const handleYesClick = () => {
    navigate('/next-chapter');
  };

  const handleNoClick = () => {
    setResponse(
      `You *really* thought we'd put ourselves through that agin? Sigh`
    );
    document.querySelector('.no-button').classList.add('fade-out');
  };

  return (
    <div className="Home">
      <audio
        ref={audioRef}
        autoPlay
        controls
        loop
        muted={false}
        id="home-audio"
      >
        <source src={exploringStanley} type="audio/mpeg"></source>
      </audio>
      <header className="Home-header">
        <h1>The Seals-Nutt Parable</h1>
        {showInstructions && (
          <h2 className="instructions">
            Okay, the first task is simple really: Press the Right button.
          </h2>
        )}
        <p>{response}</p>
        {!showInstructions &&
          response ===
            "🎉 Congrats! You've finally followed the instructions correctly. (Took you long enough...)" &&
          showNextChapter && (
            <p className="next-chapter">
              Are you ready to enter the next chapter of The Seals-Nutt Parable?
            </p>
          )}
      </header>
      <div
        className={`button-container ${
          response === leftButtonMessages[3]
            ? 'button-container-arrows-visible'
            : ''
        }`}
      >
        {!showNextChapter && leftButtonClicks < leftButtonMessages.length && (
          <button
            className={
              response === leftButtonMessages[5] ? 'left-button-fade' : ''
            }
            onClick={handleLeftButtonClick}
          >
            Left Button
          </button>
        )}
        <div className={`right-button-wrapper`}>
          {!showNextChapter ? (
            <>
              <button
                className={`${
                  response === leftButtonMessages[3] ? 'right-button-large' : ''
                }`}
                onClick={handleRightButtonClick}
              >
                Right Button
              </button>
              {response === rightButtonMessagesEarly[3] && (
                <div className="caution-tape"></div>
              )}
              {response ===
                'You know, the right button is there for a reason. Just saying.' && (
                <div className="arrows">
                  <i className="fa-solid fa-angles-right arrow-left"></i>
                  <i className="fa-solid fa-arrow-trend-down arrow-left-top-corner"></i>
                  <i className="fa-solid fa-arrow-down arrow-top"></i>
                  <i className="fa-solid fa-caret-left arrow-right-top-corner"></i>
                  <i className="fa-solid fa-reply arrow-right"></i>
                  <i className="fa-solid fa-angle-left arrow-right-bottom-corner"></i>
                  <i className="fa-regular fa-circle-up arrow-bottom"></i>
                  <i className="fa-solid fa-hand-pointer arrow-left-bottom-corner"></i>
                </div>
              )}
            </>
          ) : (
            <div className="next-chapter-buttons">
              <button onClick={handleYesClick}>Yes</button>
              <button className="no-button" onClick={handleNoClick}>
                No
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
